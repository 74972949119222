import React, { useState } from "react"
import { PreselectedDates } from "./PreselectedDates"
import DailySchedule from "./DailySchedule"

// Helper function to get days in a month
const getDaysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate()
}

const Calendar = () => {
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1) // JavaScript Date months are 0-based
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
  const [selectedDay, setSelectedDay] = useState(null)
  const [selectedDayNumber, setSelectedDayNumber] = useState(null)

  const today = new Date()

  const getFirstDayOfWeekInMonth = (year, month) => {
    return new Date(year, month - 1, 1).getDay()
  }
  const firstDayOfWeekInMonth = getFirstDayOfWeekInMonth(
    selectedYear,
    selectedMonth
  )

  const placeholders = Array.from(
    { length: firstDayOfWeekInMonth === 0 ? 6 : firstDayOfWeekInMonth - 1 },
    () => null
  )

  const currentMonth = new Date().getMonth()
  const currentYear = new Date().getFullYear()
  const months = Array(12)
    .fill()
    .map((_, i) => {
      const month = (currentMonth + i) % 12
      const year = currentYear + Math.floor((currentMonth + i) / 12)
      return { month: month + 1, year }
    })

  const colorCodes = {
    holiday: "bg-green-100  hover:border-green-200",
    pascendi: "bg-orange-100 hover:border-orange-200",
    vacation: "bg-blue-100 hover:border-blue-200",
  }

  const daysInMonth = getDaysInMonth(selectedMonth, selectedYear)
  const monthDates = Array.from({ length: daysInMonth }, (_, i) => i + 1)

  const isSelectedDate = (day) => {
    const selectedDate = PreselectedDates.find(
      (date) =>
        date.dates &&
        date.dates.includes(day) &&
        date.month === selectedMonth &&
        date.year === selectedYear
    )
    if (selectedDate) {
      return { ...selectedDate, day } // include the day in the selected date object
    }
    return selectedDate
  }

  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ]

  return (
    <section className="w-full">
      <div className="flex flex-wrap flex-col lg:grid lg:grid-cols-[1fr_auto] w-10/12 max-w-[1000px] mx-auto ">
        {/* This is the code for the calendar on the side */}
        <div className="grid grid-cols-7 w-full lg:w-10/12 max-w-[400px] max-h-max mx-auto order-2 lg:order-1 h-[24rem] ">
          {daysOfWeek.map((day, i) => (
            <div key={i} className="text-center h-8 ">
              {day.substring(0, 2)}
            </div>
          ))}
          {placeholders.concat(monthDates).map((day, index) => {
            if (day === null) {
              return <div key={`placeholder-${index}`} />
            }

            const selectedDate = isSelectedDate(day)
            const isToday =
              day === today.getDate() &&
              selectedMonth === today.getMonth() + 1 &&
              selectedYear === today.getFullYear()

            return (
              <div
                key={day}
                className={`m-2 hover:m-1 border-2 border-transparent hover:text-black hover:bg-white text-center rounded-full aspect-square flex justify-center items-center cursor-pointer duration-300 ease-in-out ${
                  selectedDate ? colorCodes[selectedDate.label] : ""
                } ${isToday ? "bg-red-500 text-white " : "text-gray-400 "}`}
                onClick={() => setSelectedDay({ day, ...selectedDate })}
              >
                {day}
              </div>
            )
          })}
        </div>
        {/* This is the code for the months on the side */}
        <div className=" w-full lg:max-h-[17rem] overflow-auto relative lg:order-2 my-5 xl:px-5">
          <div className=" flex flex-row gap-4 py-5 lg:grid lg:grid-cols-1 w-full  ">
            {months.map((monthData, i) => (
              <div
                key={i}
                className={`py-2 px-4 border rounded-full lg:w-40 mx-auto border-gray-200 text-center cursor-pointer hover:tracking-wide duration-200 ease-in-out hover:shadow-md hover:border-b hover:border-b-gray-300 ${
                  selectedMonth === monthData.month &&
                  selectedYear === monthData.year
                    ? "bg-gray-200"
                    : ""
                }`}
                onClick={() => {
                  setSelectedMonth(monthData.month)
                  setSelectedYear(monthData.year)
                }}
              >
                {new Date(
                  monthData.year,
                  monthData.month - 1,
                  1
                ).toLocaleString("default", {
                  month: "long",
                })}
              </div>
            ))}
          </div>
        </div>

        {selectedDay && (
          <div className=" border-t col-span-2 border-gray-200 order-3 w-full">
            {selectedDay.label === "pascendi" ? (
              <div>
                {/* Background color changes according to colorCodes object in Calendar.js */}
                <p
                  className={
                    selectedDay.label === "pascendi" &&
                    "bg-orange-100 p-2 indent-2"
                  }
                >
                  <b className="mr-3">
                    {selectedDay.year && selectedDay.month && selectedDay.day
                      ? new Date(
                          selectedDay.year,
                          selectedDay.month - 1,
                          selectedDay.day
                        ).toLocaleDateString("en-US", {
                          month: "long",
                          day: "numeric",
                        })
                      : "No Pascendi"}
                  </b>{" "}
                  {selectedDay.description}
                </p>

                <DailySchedule />
              </div>
            ) : (
              <div>
                <p
                  className={
                    (selectedDay.label === "vacation"
                      ? "bg-blue-100 "
                      : "bg-green-100 ") + "p-2 indent-2"
                  }
                >
                  <b className="mr-3">
                    {selectedDay.year &&
                    selectedDay.month &&
                    selectedDay.day ? (
                      new Date(
                        selectedDay.year,
                        selectedDay.month - 1,
                        selectedDay.day
                      ).toLocaleDateString("en-US", {
                        month: "long",
                        day: "numeric",
                      })
                    ) : (
                      <span className="font-normal w-full">
                        No Event Selected
                      </span>
                    )}
                  </b>{" "}
                  {selectedDay.description}
                </p>
                <div className="grayscale opacity-50 blur-sm">
                  <DailySchedule />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </section>
  )
}

export default Calendar
