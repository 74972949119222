export const PreselectedDates = [
  {
    dates: [15],
    month: 7,
    year: 2023,
    label: "pascendi",
    description: "Open Registration Period",
  },
  {
    dates: [15],
    month: 8,
    year: 2023,
    label: "pascendi",
    description: "Registration Period Closed",
  },
  {
    dates: [8, 15, 22, 29],
    month: 9,
    year: 2023,
    label: "pascendi",
    description: "Day at Pascendi",
  },
  {
    dates: [6, 20, 27],
    month: 10,
    year: 2023,
    label: "pascendi",
    description: "Day at Pascendi",
  },
  {
    dates: [3, 10, 17],
    month: 11,
    year: 2023,
    label: "pascendi",
    description: "Day at Pascendi",
  },
  {
    dates: [12, 19, 26],
    month: 1,
    year: 2024,
    label: "pascendi",
    description: "Day at Pascendi",
  },
  {
    dates: [2, 9, 16, 23],
    month: 2,
    year: 2024,
    label: "pascendi",
    description: "Day at Pascendi",
  },
  {
    dates: [1, 8, 15],
    month: 3,
    year: 2024,
    label: "pascendi",
    description: "Day at Pascendi",
  },
  {
    dates: [12, 19, 26],
    month: 4,
    year: 2024,
    label: "pascendi",
    description: "Day at Pascendi",
  },
  {
    dates: [3, 10, 17, 24, 31],
    month: 5,
    year: 2024,
    label: "pascendi",
    description: "Day at Pascendi",
  },
  {
    dates: [15],
    month: 10,
    year: 2023,
    label: "holiday",
    description: "Confirmations in Davie, FL",
  },
  {
    dates: [20, 22, 23],
    month: 9,
    year: 2023,
    label: "holiday",
    description: "Ember Days",
  },
  {
    dates: [20, 22, 23],
    month: 12,
    year: 2023,
    label: "holiday",
    description: "Ember Days",
  },
  {
    dates: [23],
    month: 11,
    year: 2023,
    label: "holiday",
    description: "Thanksgiving Day",
  },
  {
    dates: [8],
    month: 12,
    year: 2023,
    label: "holiday",
    description: "Feast of The Assumption of the Blessed Virgin Mary",
  },
  {
    dates: [24, 25],
    month: 12,
    year: 2023,
    label: "holiday",
    description: "The Nativity of Our Lord Jesus Christ",
  },
  {
    dates: [25, 26, 27, 28, 29, 30],
    month: 3,
    year: 2024,
    label: "holiday",
    description: "Holy Week",
  },
  {
    dates: [31],
    month: 3,
    year: 2024,
    label: "holiday",
    description: "Easter Sunday",
  },
  {
    dates: [1],
    month: 6,
    year: 2024,
    label: "vacation",
    description: "End of Year Celebration",
  },
]
