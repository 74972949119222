import * as React from "react"

const DailySchedule = () => {
  return (
    <div className="w-full text-sm md:text-base">
      <h2 className="w-full text-3xl text-center py-3">
        PASCENDI DAY SCHEDULE
      </h2>
      <div className="flex flex-row text-center uppercase items-center justify-center ">
        <div className="grid grid-cols-1 min-w-[11rem]">
          <p className="h-10 px-2 flex justify-center items-center border-b">
            HOURS
          </p>
          <p className="h-10 px-2 flex justify-center items-center">
            12:00PM - 12:25PM
          </p>
          <p className="h-10 px-2 flex justify-center items-center">
            12:30PM - 1:20PM
          </p>
          <p className="h-10 px-2 flex justify-center items-center">
            1:25PM - 2:15PM
          </p>
          <p className="h-10 px-2 flex justify-center items-center">
            2:20PM - 2:50PM
          </p>
          <p className="h-10 px-2 flex justify-center items-center">
            2:55PM - 3:45PM
          </p>
          <p className="h-10 px-2 flex justify-center items-center">
            3:50PM - 4:40PM
          </p>
          <p className="h-10 px-2 flex justify-center items-center">
            4:50PM - 5:30PM
          </p>
        </div>
        <div className="flex flex-row overflow-scroll relative">
          <div className="h-10 absolute px-2 top-10 flex items-center xl:justify-center w-[99%] left-1 bg-white border-b z-10 min-w-[43.5rem]">
            <p className="sticky left-[50%] -translate-x-[50%] ">PRAYERS</p>
          </div>
          <div className="h-10 absolute px-2 top-40 flex items-center xl:justify-center w-[99%] left-1 bg-white border-b border-t z-10 min-w-[43.5rem]">
            <p className="sticky left-[50%] -translate-x-[50%] ">LUNCH</p>
          </div>
          <div className="  grid grid-cols-1 min-w-[11rem] border-l">
            <p className="bg-gray-100 h-10 px-2  flex items-center justify-center">
              WHITE GROUP
            </p>
            <p className="h-10 px-2 flex justify-center items-center">A</p>
            <p className="h-10 px-2 flex justify-center items-center border-b">
              SCIENCE
            </p>
            <p className="h-10 px-2 flex justify-center items-center">
              literature
            </p>
            <p className="h-10 px-2 flex justify-center items-center">lunch</p>
            <p className="h-10 px-2 flex justify-center items-center">
              life skills
            </p>
            <p className="h-10 px-2 flex justify-center items-center border-b border-t">
              fine arts
            </p>
            <p className="h-10 px-2 flex justify-center items-center">chants</p>
          </div>
          <div className="w-full grid grid-cols-1  min-w-[11rem] relative border-l">
            <p className="bg-yellow-400 h-10 px-2 flex justify-center items-center ">
              {" "}
              YELLOW GROUP
            </p>
            <p className="h-10 px-2 flex justify-center items-center"> </p>
            <p className="h-10 px-2 flex justify-center items-center border-b">
              life skills
            </p>
            <p className="h-10 px-2 flex justify-center items-center">
              SCIENCE
            </p>
            <p className="h-10 px-2 flex justify-center items-center">lunch</p>
            <p className="h-10 px-2 flex justify-center items-center">
              fine arts
            </p>
            <p className="h-10 px-2 flex justify-center items-center border-b border-t">
              literature
            </p>
            <p className="h-10 px-2 flex justify-center items-center">chants</p>
          </div>
          <div className=" grid grid-cols-1  min-w-[11rem] border-l">
            <p className="bg-red-700 h-10 px-2 flex justify-center items-center text-white ">
              {" "}
              RED GROUP
            </p>
            <p className="h-10 px-2 flex justify-center items-center"> </p>
            <p className="h-10 px-2 flex justify-center items-center border-b">
              fine arts
            </p>
            <p className="h-10 px-2 flex justify-center items-center ">
              life skills
            </p>
            <p className="h-10 px-2 flex justify-center items-center">lunch</p>
            <p className="h-10 px-2 flex justify-center items-center">
              literature
            </p>
            <p className="h-10 px-2 flex justify-center items-center border-b border-t">
              SCIENCE
            </p>
            <p className="h-10 px-2 flex justify-center items-center">
              Religion
            </p>
          </div>
          <div className=" grid grid-cols-1  min-w-[11rem]  border-l">
            <p className="bg-blue-700 h-10 px-2 text-white flex items-center justify-center">
              BLUE GROUP
            </p>
            <p className="h-10 px-2 flex justify-center items-center"> </p>
            <p className="h-10 px-2 flex justify-center items-center border-b">
              literature
            </p>
            <p className="h-10 px-2 flex justify-center items-center">
              fine arts
            </p>
            <p className="h-10 px-2 flex justify-center items-center">lunch</p>
            <p className="h-10 px-2 flex justify-center items-center">
              SCIENCE
            </p>
            <p className="h-10 px-2 flex justify-center items-center border-b border-t">
              life skills
            </p>
            <p className="h-10 px-2 flex justify-center items-center">
              Religion
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DailySchedule
