import React from "react"
import Calendar from "../components/Calendar"
// import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js"
// import { useState } from "react"
// import { useEffect } from "react"
// import { navigate } from "gatsby"

const CurrentDev = () => {
  return (
    <div>
      <Calendar />
    </div>
  )
}

export default CurrentDev

// const TicketForm = () => {
//   const stripe = useStripe()
//   const elements = useElements()
//   const [ticketData, setTicketData] = useState({})
//   const [loading, setLoading] = useState(false)

//   const handleSubmit = async (event) => {
//     event.preventDefault()

//     if (!stripe || !elements) {
//       // Stripe.js has not loaded yet. Make sure to disable
//       // form submission until Stripe.js has loaded.
//       return
//     }

//     setLoading(true)

//     const { error, paymentMethod } = await stripe.createPaymentMethod({
//       type: "card",
//       card: elements.getElement(CardElement),
//     })

//     if (error) {
//       console.log("Error:", error)
//       setLoading(false)
//     } else {
//       setTicketData({
//         ...ticketData,
//         paymentMethodId: paymentMethod.id,
//         price: calculatePrice(),
//       })

//       // Send the payment method and the amount to your server
//       // to create a charge
//       const response = await fetch("/.netlify/functions/charge", {
//         method: "POST",
//         body: JSON.stringify({
//           paymentMethodId: paymentMethod.id,
//           price: calculatePrice(),
//         }),
//       })

//       if (response.ok) {
//         // Your server has successfully created the charge
//         const data = await response.json()
//         // save the data into the google sheet
//         saveDataToSheet(ticketData)
//         navigate("/success")
//       } else {
//         // handle error
//         console.log("Error:", response.statusText)
//         navigate("/error")
//       }
//       setLoading(false)
//     }
//   }

//   const calculatePrice = () => {
//     if (ticketData.age < 20) {
//       return 3500
//     } else {
//       return 4000
//     }
//   }

//   const saveDataToSheet = async (ticketData) => {
//     const sheetUrl = `https://sheets.googleapis.com/v4/spreadsheets/${YOUR_SPREADSHEET_ID}/values/Sheet1:append?key=${YOUR_API_KEY}`
//     const data = {
//       range: "Sheet1",
//       majorDimension: "ROWS",
//       values: [Object.values(ticketData)],
//     }
//     await fetch(sheetUrl, {
//       method: "POST",
//       body: JSON.stringify(data),
//     })
//   }

//   return (
//     <form onSubmit={handleSubmit}>
//       <label>
//         Name
//         <input
//           name="name"
//           type="text"
//           required
//           onChange={(e) =>
//             setTicketData({ ...ticketData, name: e.target.value })
//           }
//         />
//       </label>
//       <label>
//         Age
//         <input
//           name="age"
//           type="number"
//           required
//           onChange={(e) =>
//             setTicketData({ ...ticketData, age: e.target.value })
//           }
//         />
//       </label>
//       <label>
//         Email
//         <input
//           name="email"
//           type="email"
//           required
//           onChange={(e) =>
//             setTicketData({ ...ticketData, email: e.target.value })
//           }
//         />
//       </label>
//       <label>
//         Payment
//         <CardElement />
//       </label>
//       <button type="submit" disabled={loading}>
//         Buy Ticket
//       </button>
//     </form>
//   )
// }

// export default TicketForm
